import QuestData from '../../data/all_quests.json';

export const processUpdatedQuests = (storedQuests, storedVersion) => {
  const updatedQuests = JSON.parse(JSON.stringify(QuestData['quests']));
  for (const newQuest of updatedQuests) {
    if (Array.isArray(storedQuests)) {
      let searchName = newQuest.name;
      if (storedVersion <= 19) {
        if (searchName === 'The Alchemist’s Pursuit') {
          searchName = 'Castle Clash';
        } else if (searchName === 'The Unseen') {
          searchName = 'Braving The Shadows';
        } else if (searchName === 'Cursed Lands') {
          searchName = 'Green-Skinned Scum';
        } else if (searchName === 'Secret Omens') {
          searchName = 'Mystic Insights';
        } else if (searchName === 'Unseen') {
          searchName = 'Faith In The Unknown';
        } else if (searchName === 'Glimpse of the Dark') {
          searchName = 'Tide Of Darkness';
        } else if (searchName === 'Buggy Bonds') {
          searchName = 'My Favorite Fleshy';
        } else if (searchName === 'My Favorite Fleshy') {
          searchName = 'Buggy Bonds';
        } else if (searchName === 'Piercing the Chill') {
          searchName = 'Hidden Truths';
        } else if (searchName === 'Too Cold') {
          searchName = 'Brutish Truths';
        } else if (searchName === 'Precise as Ice') {
          searchName = 'Silent Entry';
        } else if (searchName === 'Frayed Secrets') {
          searchName = 'Elegance in Execution';
        } else if (searchName === 'In Shambles') {
          searchName = 'Sprucing Up';
        } else if (searchName === 'Loyalty Remains') {
          searchName = 'The Elite And Their Secrets';
        } else if (searchName === 'Guiding Light') {
          searchName = 'Not Friendly';
        } else if (searchName === 'Not Friendly') {
          searchName = 'Guiding Light';
        } else if (searchName === 'Ceremonial Cup') {
          searchName = 'Bundle of Bangles';
        } else if (searchName === 'A Noble’s Dark Ties') {
          searchName = 'Golden Dreams';
        } else if (searchName === 'Royal Secrets') {
          searchName = 'Colossal Takedown';
        } else if (searchName === 'Lost to Time') {
          searchName = 'Mystical Gems';
        } else if (searchName === 'Anger Management') {
          searchName = 'Battle Forge';
        } else if (searchName === 'Silent Witnesses') {
          searchName = 'An Oath Unbroken';
        } else if (searchName === 'Stone Cold') {
          searchName = "Warrior's Reckoning";
        } else if (searchName === 'Bright Ideas') {
          searchName = 'Pelt Pursuit';
        }
      }

      if (storedVersion < 24) {
        const questMap = {
          'Vials and Venom': "Lion's Lair",
          'Ruins Reconnaissance': 'Text_DesignData_Quest_Armourer_Title_10',
          'Ale-Soaked Insights': 'Text_DesignData_Quest_Armourer_Title_11',
          'Cloaked Secrets': 'Text_DesignData_Quest_Armourer_Title_12',
          'Tide Of Darkness': 'The Dark Hunt',
          "Gobbo's Lady Friend": "Gobo's Cavern Cleansing",
          'Ale’s Well That Ends Well': 'Closing Time',
          'Gold-Cloaked Secrets': 'Royal Stash',
          'Fable or Fact': 'Wails In The Walls',
          'Silent Observer': 'Tip From A Green Skin',
          'Frozen Heirlooms': 'Froststalker',
          "Ember's Amidst Frost": 'Embers Amidst Frost',
          'Frostbound Carnage': 'Learning Of The Rebellion',
          'Frostborn Beasts': 'Self-Preservation',
          'Frozen Enigmas': 'Text_DesignData_Quest_Woodsman_Title_13',
          'Arctic Invaders': 'Text_DesignData_Quest_Woodsman_Title_14',
          'Collect and Deliver': 'Text_DesignData_Quest_Woodsman_Title_15',
        };

        if (questMap[searchName]) {
          searchName = questMap[searchName];
        }
      }

      const oldQuest = storedQuests.find((item) => item.name === searchName);

      if (oldQuest) {
        newQuest['completed'] = oldQuest?.['completed'] === true;
        newQuest['favorited'] = oldQuest?.['favorited'] === true;

        for (const key of Object.keys(oldQuest.objectives)) {
          if (newQuest.objectives[key]) {
            for (const oldTask of oldQuest.objectives[key]) {
              const taskToUpdate = newQuest.objectives[key].find(
                (task) => task.name === oldTask.name,
              );

              if (taskToUpdate) {
                taskToUpdate['countCompleted'] =
                  oldTask.countCompleted != undefined
                    ? oldTask.countCompleted
                    : 0;
              }
            }
          }
        }
      }
    }
  }
  return [...updatedQuests];
};
